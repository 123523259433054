import "../products-list.scss"

import React from "react"
import { Link } from "gatsby"

import ProductTile from "components/ProductTile/ProductTile"

const ProductsNewest = ({ allWpProductsNewest, setCartConfirmation }) => {
  return (
    <div className="row row__products row__products--newest">
      {allWpProductsNewest &&
        allWpProductsNewest.nodes.map((item, index) => (
          <div className="col-xl-3 col-md-6" key={index}>
            <ProductTile
              id={item.id}
              databaseId={item.databaseId}
              slug={item.slug}
              title={item.name}
              image={item.image}
              price={item.price}
              promoPrice={item.salePrice}
              stockQuantity={item.stockQuantity}
              setCartConfirmation={setCartConfirmation}
              product_data={item}
            />
          </div>
        ))}
      <div className="col-xl-3 col-md-6">
        <Link to="/laptopy/" className="row__products-show-all">
          Zobacz wszystkie
        </Link>
      </div>
    </div>
  )
}

export default ProductsNewest
