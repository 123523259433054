import "../products-list.scss"

import React from "react"
import { Link } from "gatsby"
import LazyLoad from "react-lazyload"

const ProductsCompanies = () => {
  return (
    <div className="row row__products row__products--companies">
      <div className="col">
        <Link to="/apple/">
          <LazyLoad>
            <img
              src={require("assets/images/logo-company-apple.svg").default}
              alt=""
            />
          </LazyLoad>
        </Link>
      </div>
      <div className="col">
        <Link to="/fujitsu/">
          <LazyLoad>
            <img
              src={require("assets/images/logo-company-fujitsu.svg").default}
              alt=""
            />
          </LazyLoad>
        </Link>
      </div>
      <div className="col">
        <Link to="/dell/">
          <LazyLoad>
            <img
              src={require("assets/images/logo-company-dell.svg").default}
              alt=""
            />
          </LazyLoad>
        </Link>
      </div>
      <div className="col">
        <Link to="/ibm-lenovo/">
          <LazyLoad>
            <img
              src={require("assets/images/logo-company-ibm.svg").default}
              alt=""
            />
          </LazyLoad>
        </Link>
      </div>
      <div className="col">
        <Link to="/ibm-lenovo/">
          <LazyLoad>
            <img
              src={require("assets/images/logo-company-lenovo.svg").default}
              alt=""
            />
          </LazyLoad>
        </Link>
      </div>
      <div className="col">
        <Link to="/hp-compaq/">
          <LazyLoad>
            <img
              src={require("assets/images/logo-company-hp.svg").default}
              alt=""
            />
          </LazyLoad>
        </Link>
      </div>
    </div>
  )
}

export default ProductsCompanies
