import "./styles.scss"

import React, { useState } from "react"

import Input from "components/Input/Input"
import Button from "components/Button/Button"

const ProductFormPass = ({ setPass, productID, productPass }) => {
  const [input, setInput] = useState(null)
  const [error, setError] = useState(null)

  const onFormSubmit = event => {
    event.preventDefault()

    setPass(input)

    input !== productPass && setError(true)

    input === productPass &&
      localStorage.setItem(`memtech-private-product-${productID}`, input)
  }

  return (
    <section className="container-fluid">
      <form onSubmit={onFormSubmit} className="products__form">
        <h4 class="page__sub-header ">Wymagane podanie hasła</h4>
        <Input
          type="password"
          name="password"
          placeholder="Hasło dostępowe"
          value={input}
          onChange={e => setInput(e.target.value)}
          errors={error}
        />
        {error && <span className="products__form-error">Błędne hasło</span>}
        <div className="products__form-cta">
          <Button type="submit">Odblokuj</Button>
        </div>
      </form>
    </section>
  )
}

export default ProductFormPass
