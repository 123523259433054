/* eslint-disable jsx-a11y/label-has-associated-control */
import "styles/pages/home.scss"

import React, { useState, useEffect } from "react"
import Slider from "react-slick"
import { graphql, Link } from "gatsby"
import { toast } from "react-toastify"
import { useQueryParam, StringParam } from "use-query-params"

import mapProductsFormAnalytics from "utils/mapProductsFormAnalytics"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import {
  ProductsNewest,
  ProductsCompanies,
  ProductsRecommended,
} from "../components/Products"
import ProductCartConfirmation from "components/Product/ProductCartConfirmation/ProductCartConfirmation"

const Index = ({ data }) => {
  const [verificationParam] = useQueryParam("verification", StringParam)
  const [cartConfirmation, setCartConfirmation] = useState(false)

  const indexData = {
    cartConfirmation,
    setCartConfirmation,
  }

  const homeSlider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  useEffect(() => {
    verificationParam === "success" &&
      toast.success("Pomyślnie aktywowano konto!")
  }, [verificationParam])

  useEffect(() => {
    const all_products = [
      ...(data?.allWpProductsNewest?.nodes || []),
      ...(data?.allWpProductsSuggested?.nodes || []),
    ]
    window.dataLayer?.push({ ecommerce: null })
    window.dataLayer.push({
      ecommerce: {
        currencyCode: "PLN",
        impressions: mapProductsFormAnalytics({
          products: all_products,
        }),
      },
    })
  }, []) //eslint-disable-line

  return (
    <Layout>
      <Seo title="Strona główna" />

      <section className="home-slider">
        <Slider {...homeSlider}>
          {data.optionsPage.nodes[0].optionsPage.acfOptions.homeSlider.map(
            (item, index) => (
              <div key={index}>
                <div
                  className={`home-slider__slide home-slider__slide--${item.homeSliderBg}`}
                >
                  <div className="container-fluid">
                    <div className="row align-items-center">
                      {!index % 2 ? (
                        <div className="col-lg-5 offset-lg-1 col-md-6 text-center">
                          {item.homeSliderImage && (
                            <img
                              className="img-fluid"
                              src={item.homeSliderImage?.localFile?.publicURL}
                              alt={item.homeSliderHeader}
                            />
                          )}
                        </div>
                      ) : null}
                      <div
                        className={`col-md-5${index % 2 ? " offset-md-1" : ""}`}
                      >
                        {item.homeSliderType === "new" ? (
                          <label>Nowość</label>
                        ) : item.homeSliderType === "promo" ? (
                          <label className="label--violet">Promocja</label>
                        ) : (
                          ""
                        )}
                        {item.homeSliderLink ? (
                          <Link
                            to={item.homeSliderLink.url.replace("/produkt", "")}
                          >
                            <h1>{item.homeSliderHeader}</h1>
                            <h2>{item.homeSliderSubheader}</h2>
                          </Link>
                        ) : (
                          <>
                            <h1>{item.homeSliderHeader}</h1>
                            <h2>{item.homeSliderSubheader}</h2>
                          </>
                        )}
                      </div>
                      {index % 2 ? (
                        <div className="col-lg-5 col-md-6 text-center">
                          {item.homeSliderImage && (
                            <img
                              className="img-fluid"
                              src={item.homeSliderImage?.localFile?.publicURL}
                              alt={item.homeSliderHeader}
                            />
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </Slider>
      </section>

      <section className="home-products">
        <div className="container-fluid">
          <PageHeader title="Nowości" />
          <ProductsNewest
            {...indexData}
            allWpProductsNewest={data.allWpProductsNewest}
          />
        </div>
      </section>

      <section className="home-products">
        <div className="container-fluid">
          <PageHeader title="Strefy marek" />
          <ProductsCompanies />
        </div>
      </section>

      <section className="home-products">
        <div className="container-fluid">
          <PageHeader title="Polecane" />
          <ProductsRecommended
            {...indexData}
            allWpProductsSuggested={data.allWpProductsSuggested}
          />
        </div>
      </section>

      {cartConfirmation && <ProductCartConfirmation {...indexData} />}
    </Layout>
  )
}

export const query = graphql`
  query {
    optionsPage: allWp {
      nodes {
        optionsPage {
          acfOptions {
            homeSlider {
              homeSliderHeader
              homeSliderSubheader
              homeSliderLink {
                url
              }
              homeSliderImage {
                sourceUrl
                localFile {
                  publicURL
                }
              }
              homeSliderBg
              homeSliderType
            }
          }
        }
      }
    }

    allWpProductsNewest: allWpProduct(
      limit: 7
      sort: { fields: date, order: DESC }
      filter: {
        productCategories: {
          nodes: {
            elemMatch: { slug: { ne: "podzespoly", nin: "private-category" } }
          }
        }
      }
    ) {
      nodes {
        id
        databaseId
        name
        slug
        image {
          sourceUrl
          localFile {
            publicURL
          }
        }
        paProducents {
          nodes {
            name
          }
        }
        productCategories {
          nodes {
            name
          }
        }
        ... on WpSimpleProduct {
          price
          salePrice
          stockQuantity
        }
      }
    }
    allWpProductsSuggested: allWpProduct(
      limit: 7
      skip: 7
      sort: { fields: date, order: DESC }
      filter: {
        productCategories: {
          nodes: {
            elemMatch: { slug: { ne: "podzespoly", nin: "private-category" } }
          }
        }
      }
    ) {
      nodes {
        id
        databaseId
        name
        slug
        image {
          sourceUrl
          localFile {
            publicURL
          }
        }
        paProducents {
          nodes {
            name
          }
        }
        productCategories {
          nodes {
            name
          }
        }
        ... on WpSimpleProduct {
          price
          salePrice
          stockQuantity
        }
      }
    }
  }
`

export default Index
